<template>
    <modal ref="modalCompartirBibliotecaEditar" titulo="Compartir carpeta" tamano="custom-modal-size" adicional="Guardar" @adicional="compartirCarpeta">
        <div class="container mx-0">
            <div class="row mb-3">
                <div class="col-12">
                    <label for="agrupacion" class="f-12 pl-2">Selecciona una agrupación</label>
                    <el-select v-model="checked" placeholder="Seleccionar" size="small" class="w-100" @change="getViviendasAgrupacion">
                        <el-option 
                        v-for="(data,index) in agrupaciones" 
                        :key="index" 
                        :label="data.nombre"
                        :value="data.id"
                        />
                    </el-select>
                </div>
            </div>
            <div v-if="checked != null" class=" justify-content-center">
                <p class="text-black f-light f-15 lh-16">Selecciona las viviendas con las que compartirás esta carpeta</p>
                <div class=" f-14 mt-1 my-2">
                    <el-checkbox v-model="checkAll" class="el-checkbox-negro my-2 text-black" @change="seleccionarTodos" >
                        <div class="d-middle">
                            <p class="ml-2 text-black mb-3"> Seleccionar todo</p>
                        </div>
                    </el-checkbox>
                    <div v-for="(vivienda,i) in viviendas" :key="i">
                        <el-checkbox v-model="vivienda.checked" :true-label="1" :false-label="0" class="el-checkbox-negro my-2 text-black w-100 f-300" :label="vivienda">
                            <div class="d-middle">
                                <p class="ml-2 text-black"> {{ vivienda.nombre }}</p>
                            </div>
                        </el-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Carpetas from '~/services/bibliotecas/bibliotecas-carpetas'
export default {
    data(){
        return {
            inputComentario: '',
            checked: null,
            agrupaciones: [],
            viviendas: [],
            ids: [],
            checkAll: false,
            agrupacion: {}
        }
    },
    watch: {
        ids(){
            if (this.ids.length == this.viviendas.length && this.viviendas.length != 0){
                this.checkAll = true;
            } else {
                this.checkAll = false;
            }
        }
    },
    methods: {
        toggle(){
            this.clearAll();
            this.getAgrupacionesViviendas();
            this.$refs.modalCompartirBibliotecaEditar.toggle();
        },
        async getAgrupacionesViviendas(){
            const { data } = await Carpetas.getAgrupaciones();
            this.agrupaciones = data.agrupaciones;
        },
        async getViviendasAgrupacion(item){
            const params = {
                idCarpeta: this.$route.params.idCarpeta
            }

            this.agrupacion = this.agrupaciones.find(element => element.id == item);
            const { data } = await Carpetas.getViviendas(item, params);
            this.viviendas = data.viviendas;
        },
        seleccionarTodos(e){
            if (e == true){
                this.viviendas.forEach(element => {
                    element.checked = 1
                });
            } else {
                this.viviendas.forEach(element => {
                    element.checked = 0
                });
            }
        },
        compartirCarpeta(){
            if (this.checkAll == true){
                this.$emit('compartir', this.agrupacion)
            } else {
                this.agrupacion.viviendas = this.viviendas.filter(data => data.checked == 1)
                this.$emit('compartir', this.agrupacion)
            }
            this.$refs.modalCompartirBibliotecaEditar.toggle();
        },
        clearAll(){
            this.checked = null;
            this.ids = [];
            this.viviendas = [];
            this.checkAll = false;
            this.agrupacion = {}
        },

    }
}
</script>
<style lang="scss">
.custom-modal-size{
    max-width: 358px;
}
</style>